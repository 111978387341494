import React, { useEffect, useState } from "react"

import moment from 'moment'




import { GatsbyImage, getImage, StaticImage} from "gatsby-plugin-image"
import Layout from "../components/layout"
import Slider from "react-slick";
import Seo from "../components/seo"
import { useStaticQuery, graphql, Link } from "gatsby"



const BlogListing = () => {

  

      const data = useStaticQuery(graphql`
      
        query blogsposts {
          allWpPost(sort: {fields: date, order: DESC}) {
            edges {
              node {
                id
                title
                slug
                content
                excerpt
                date(formatString: "YYYY-MM-DD")
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: CONSTRAINED, quality: 100, placeholder: BLURRED,width:534)
                      }
                    }
                  }
                }
              }
            }
          }
          
        
        }
          
          
    `)
    // Array of all news articles
  const allNews = data.allWpPost.edges
  

  // State for the list
  const [list, setList] = useState([...allNews.slice(0, 3)])

  // State to trigger oad more
  const [loadMore, setLoadMore] = useState(false)

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(allNews.length > 3)

  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true)
  }

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < allNews.length
      const nextResults = isMore
        ? allNews.slice(currentLength, currentLength + 3)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line

  //Check if there is more
  useEffect(() => {
    const isMore = list.length < allNews.length
    setHasMore(isMore)
  }, [list]) //eslint-disable-line


return (

<Layout>

<section className = "top_post">

        <div className = "container">

                <div className = "post_row">

                <div className = "post_intro">

                    <h1>Project management tips & trends, delivered.</h1>
                    <p>Join productivity hackers from around the world that receive writeclick E-Store blog newsletter.</p>
                </div>
                {list.map((article,index) => (
                    index == 0 ? 
                    <Link to = {`/blog/${article.node.slug}`} >
                    <div className = "latest_post" key = {article.node.title} >
                    <div className="post_box">
                    <GatsbyImage
  image={getImage(article.node.featuredImage.node.localFile)}
  quality = "100"
  alt={article.node.title} />
<div className="post_inner">
<h2>{article.node.title}</h2>

<div className="post_time">

<div className="time_slot">
<i className="fa fa-calendar"></i>
<span> {moment(article.node.date).fromNow()} </span>      
</div>

<div className="time_slot">
<i className="fa fa-clock-o" ></i>
<span dangerouslySetInnerHTML={{ __html: article.node.excerpt.replace( /(<([^>]+)>)/ig, '') }}></span>

</div>

</div>
</div>



                    </div>
    
            </div>
            </Link>

                    : ''
               
                ))}
                </div>


        </div>

</section>


<section className = "blog_listing">

<div className="container">

        <h1>Articles</h1>

<div className = "blog_listing_grid">


{list.map((article) => (
         
         <Link to = {`/blog/${article.node.slug}`} id = {article.node.id} key = {article.node.title}>
  <div className = "latest_post"  >
  
                        <div className="post_box">
                        <GatsbyImage
      image={getImage(article.node.featuredImage.node.localFile)}
      quality = "100"
      alt={article.node.title} />
<div className="post_inner">
<h2>{article.node.title}</h2>

<div className="post_time">

<div className="time_slot">
  <i className="fa fa-calendar"></i>
  <span>{moment(article.node.date).fromNow()}</span>      
  </div>

{
article.node.excerpt ? 

<div className="time_slot">
<i className="fa fa-clock-o" ></i>
<span dangerouslySetInnerHTML={{ __html: article.node.excerpt.replace( /(<([^>]+)>)/ig, '') }}></span>

</div>
: 
''
}


</div>
</div>



                        </div>
        
                </div>
                </Link>






       ))}





</div>
{hasMore ? (
        <button onClick={handleLoadMore}>Load More</button>
      ) : (
        <h5>No more results...</h5>
      )}
</div>


</section>


{/*


<section className="writer">

            <div className="container">
                <h1>featured writers</h1>

                <div className = "wirters_slider">
                <Slider {...settings}>
     

                {users.edges.map((user,index) => (

    
      user.node.users_profiles.userRole == "Author" 
      
        ?
        
        <div>

        <GatsbyImage
              image={getImage(user.node.users_profiles.userProfile.localFile)}
              quality = "100"
              alt={user.node.firstName} />
        
        <h3>{user.node.firstName} {user.node.lastName} </h3>
        <h6>{user.node.description}</h6>
        </div>
        
        : 
        
        ''
                
     






                ))}
     
               

                

                
  
     
     
      
     
     
     
     
     
    
     
    </Slider>
    </div>         
            </div>


</section>
 */}
    
</Layout>







)}



export default BlogListing